import React from 'react';
import { Select as MaterialSelect, SelectProps, TypeLabel } from '@c2fo/react-components';

export const Select: React.FC<SelectProps> = ({ label, ...props }: SelectProps) => {
  return (
    <>
      <TypeLabel style={{ marginBottom: 3 }}>{label}</TypeLabel>
      <MaterialSelect {...props} />
    </>
  );
};
