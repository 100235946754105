import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  makeStyles,
  Theme,
  Box,
  TypeSectionHeader,
  AncillaryButton,
  TypeLabel,
  IconButton,
  BanIcon,
  Modal,
  PrimaryButton,
  Tooltip,
  TypeBase,
} from '@c2fo/react-components';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { GET_OFFER_HISTORY, MARK_AS_STOP } from '../../../services/queries/offerHistory';
import { ActionableOfferScheduleHistory, CursorPaging, Status } from '../../../../generated-types/api-types-graphql';
import { CampaignHistoryTableHeader } from './CampaignHistoryTableHeader/CampaignHistoryTableHeader';
import { DEFAULT_SORT_COLUMN_HISTORY, SORT_DIRECTION } from '../../../constants';
import { dateFormatter, getCampaignTypes } from '../../../utils/utils';

export const PAGE_SIZE = 10;
export const PRIOR_DAYS = 30;

export const PRIOR_DATE = new Date().setDate(new Date().getDate() - PRIOR_DAYS);
export const FORMATTED_PRIOR_DATE = format(new Date(PRIOR_DATE), 'yyyy-MM-dd');

export interface PaginationDetailsProps {
  pageNumber: number;
  pagination: CursorPaging;
}

const useStyles = makeStyles<Theme>((theme) => ({
  table: {
    marginTop: theme.spacing(2),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  updateAlert: {
    marginRight: theme.spacing(4),
  },
}));

export const CampaignHistoryTable: React.FC = () => {
  const classes = useStyles();
  const [sortParaams, setSortParams] = useState({
    field: DEFAULT_SORT_COLUMN_HISTORY.dbField,
    direction: SORT_DIRECTION.DESC,
    fieldId: DEFAULT_SORT_COLUMN_HISTORY.id,
  });

  const [isModalPromtOpen, setModalPromtOpen] = useState(false);

  const [{ pageNumber, pagination }, setPaginationDetails] = useState<PaginationDetailsProps>({
    pageNumber: 1,
    pagination: {
      after: '',
      first: PAGE_SIZE,
    },
  });

  const [markStopStatusOfOngoingCampaign, { data: _stopMutationData }] = useMutation(MARK_AS_STOP, {
    onCompleted: () => {
      refetch();
    },
  });

  const { data: offerHistoryData, loading, refetch } = useQuery(GET_OFFER_HISTORY, {
    fetchPolicy: 'no-cache',
    variables: {
      paging: pagination,
      sorting: {
        field: sortParaams.field,
        direction: sortParaams.direction.toUpperCase(),
      },
      filter: {
        executedOn: {
          // Fetch only last 30 Days AO schedule history data
          gte: FORMATTED_PRIOR_DATE,
        },
      },
    },
  });

  const pageInfo = offerHistoryData?.actionableOfferScheduleHistories.pageInfo ?? {};

  const [{ stopUUID, nameOfTheCampaign }, setStopUUID] = useState({
    stopUUID: '',
    nameOfTheCampaign: '',
  });

  const upcomingCampaignTableData =
    offerHistoryData?.actionableOfferScheduleHistories.edges.map(
      (item: { node: ActionableOfferScheduleHistory }) => item.node,
    ) ?? [];

  if (loading && !upcomingCampaignTableData?.length) {
    return <div>Loading....</div>;
  }

  if (!upcomingCampaignTableData?.length) {
    return (
      <Box>
        <TypeSectionHeader align="center">No Offer History</TypeSectionHeader>
      </Box>
    );
  }

  const handleStopCampaign = () => {
    markStopStatusOfOngoingCampaign({ variables: { uuid: stopUUID } });
    setModalPromtOpen(false);
    setStopUUID({
      stopUUID: '',
      nameOfTheCampaign: '',
    });
  };

  const moveToNextPage = () => {
    setPaginationDetails((prev) => ({
      pageNumber: prev.pageNumber + 1,
      pagination: {
        after: pageInfo.endCursor,
        first: PAGE_SIZE,
      },
    }));
  };

  const moveToPreviousPage = () => {
    setPaginationDetails((prev) => ({
      pageNumber: prev.pageNumber - 1,
      pagination: {
        before: pageInfo.startCursor,
        last: PAGE_SIZE,
      },
    }));
  };

  // eslint-disable-next-line no-unsafe-optional-chaining
  const totalPages = Math.ceil(offerHistoryData?.actionableOfferScheduleHistories.totalCount / PAGE_SIZE);

  return (
    <>
      <Modal
        closeButtonTestId="close-stop-modal"
        modalTitle="Confirmation"
        open={isModalPromtOpen}
        onClose={() => setModalPromtOpen(false)}
        actionsContent={<PrimaryButton onClick={handleStopCampaign}>yes</PrimaryButton>}
      >
        <Box>
          <Box p={3}>
            <TypeSectionHeader align="center">
              Are you sure you want to Stop the {nameOfTheCampaign} campaign ?
            </TypeSectionHeader>
          </Box>
        </Box>
      </Modal>

      <TableContainer className={classes.table}>
        <Table>
          <CampaignHistoryTableHeader
            sortCellId={sortParaams.fieldId}
            sortDirection={sortParaams.direction}
            handleSortSelection={setSortParams}
          />
          <TableBody>
            {upcomingCampaignTableData?.map((item: ActionableOfferScheduleHistory) => {
              const { executedOn, status, uuid: offerHistoryUuid } = item;

              const { name, owner, occurrence, srmHomeSegmentName, timeZone, isDebugMode, campaignCode } = item.offer;

              const STATUS_ICONS = new Map([
                [Status.Success, '✅'],
                [Status.Failure, '❌'],
                [Status.InProgress, '⌛'],
                [Status.Stopped, '🚫'],
              ]);

              return (
                <TableRow key={offerHistoryUuid}>
                  <TableCell>
                    <TypeLabel>{name}</TypeLabel>
                  </TableCell>
                  <TableCell>
                    {getCampaignTypes(campaignCode).map((target, index) => (
                      <TypeBase key={index}>{target}</TypeBase>
                    ))}
                  </TableCell>
                  <TableCell>
                    <TypeLabel>{srmHomeSegmentName || 'N/A'}</TypeLabel>
                  </TableCell>
                  <TableCell>
                    <TypeLabel>{occurrence}</TypeLabel>
                  </TableCell>
                  <TableCell>
                    <TypeLabel>
                      {dateFormatter(zonedTimeToUtc(executedOn, timeZone).toUTCString() ?? '', 'PPP , hh:mm:ss a')}
                    </TypeLabel>
                  </TableCell>
                  <TableCell>
                    <TypeLabel>{owner}</TypeLabel>
                  </TableCell>
                  <TableCell>
                    <TypeLabel>{isDebugMode.toString()}</TypeLabel>
                  </TableCell>
                  <TableCell>
                    <TypeLabel>{STATUS_ICONS.get(status)}</TypeLabel>
                  </TableCell>
                  <TableCell>
                    {status === Status.InProgress ? (
                      <Tooltip title={'Stop the ongoing Campaign'} placement="top" arrow>
                        <IconButton
                          data-testid={`${offerHistoryUuid}-stop`}
                          onClick={() => {
                            setStopUUID({ stopUUID: offerHistoryUuid, nameOfTheCampaign: name });
                            setModalPromtOpen(true);
                          }}
                        >
                          <BanIcon fontSize="small" color="secondary" />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      ''
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Box marginTop={2} className={classes.footer}>
          <AncillaryButton data-testid="button-back" disabled={!pageInfo.hasPreviousPage} onClick={moveToPreviousPage}>
            Back
          </AncillaryButton>
          <TypeLabel>
            Page {pageNumber} of {totalPages}
          </TypeLabel>
          <AncillaryButton data-testid="button-next" disabled={pageNumber === totalPages} onClick={moveToNextPage}>
            Next
          </AncillaryButton>
        </Box>
      </TableContainer>
    </>
  );
};
