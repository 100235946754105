import React from 'react';
import { TextField as MaterialTextField, TextFieldProps, TypeLabel } from '@c2fo/react-components';

export const TextField: React.FC<TextFieldProps> = ({ label, ...props }: TextFieldProps) => {
  return (
    <>
      <TypeLabel style={{ marginBottom: 3 }}>{label}</TypeLabel>
      <MaterialTextField {...props} />
    </>
  );
};
