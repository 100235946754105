import React, { useState } from 'react';
import {
  Box,
  TypePageTitle,
  makeStyles,
  Theme,
  TypeLabel,
  Paper,
  Checkbox,
  CTAButton,
  SecondaryButton,
  Liquidity,
  Tabs,
  Tab,
} from '@c2fo/react-components';
import { useHistory } from 'react-router-dom';
import { useSessionStorage } from 'react-use';
import { UpcomingCampaignsTable } from './UpcomingCampaignsTable/UpcomingCampaignsTable';
import { TextField } from '../../components/TextField/TextField';
import { ROUTES } from '../../Router';
import { CampaignHistoryTable } from './CampaignsHistoryTable/CampaignHistoryTable';

const useStyles = makeStyles<Theme>((theme) => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3, 2),
    },
  },
  campaignContainer: {
    display: 'flex',
    backgroundColor: 'white',
    flexDirection: 'column',
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  input: {
    width: '40%',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  dubugContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -theme.spacing(1.4),
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
    boxShadow: '0 8px 32px 0 rgba(7, 18, 37, 0.2)',
    border: `${theme.spacing(0.7)}px solid ${Liquidity.colors.supporting.deepBlue}`,
    borderRadius: '5px',
    outline: 'none',
  },
  buttonRun: {
    width: 100,
    marginLeft: 'auto',
    marginTop: theme.spacing(2),
  },
  scheduleHelperText: {
    marginLeft: theme.spacing(1),
  },
  orText: {
    margin: theme.spacing(1, 2),
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
}));

export const testIds = {
  submit: 'submit-button',
  schedule: 'schedule-button',
  debugMode: 'debug-mode-checkbox',
};

export const ScheduleActionableOfferPage: React.FC = () => {
  const [tableIndex, setTableIndex] = useState(0);
  const classes = useStyles();
  const history = useHistory();
  const [userId, setSrmHomeUserId] = useSessionStorage('userId', '');
  const [srmHomeId, setSrmHomeId] = useState(userId);
  const [isDebugMode, setDebugMode] = useState(false);

  const goToRoute = (route: string) => {
    setSrmHomeUserId(srmHomeId);
    // timout required because session storage write operation is getting interrupted
    setTimeout(() => {
      history.push(`${route}/${srmHomeId}?isDebugMode=${isDebugMode}`);
    }, 0);
  };

  return (
    <Box className={classes.body}>
      <TypePageTitle>Run Actionable Offer Campaign</TypePageTitle>
      <Paper className={classes.campaignContainer}>
        <TextField
          variant="outlined"
          value={srmHomeId}
          label="Enter your SRM Home user ID"
          placeholder="Type here"
          className={classes.input}
          onChange={(event) => {
            setSrmHomeId(event.target.value);
          }}
        />
        <Box className={classes.dubugContainer}>
          <Checkbox data-testid={testIds.debugMode} checked={isDebugMode} onChange={() => setDebugMode(!isDebugMode)} />{' '}
          <TypeLabel>Debug mode</TypeLabel>
        </Box>
        <TypeLabel>Enabling ‘Debug Mode’ will prevent data from being sent to segment.</TypeLabel>
        <Box marginTop={4} className={classes.buttonContainer}>
          <CTAButton
            data-testid={testIds.submit}
            // disabled={!validate(srmHomeId)}
            onClick={() => goToRoute(ROUTES.RUN_OFFER)}
          >
            Submit
          </CTAButton>
          <TypeLabel classes={{ root: classes.orText }} align="center">
            OR
          </TypeLabel>
          <SecondaryButton
            data-testid={testIds.schedule}
            // disabled={!validate(srmHomeId)}
            onClick={() => goToRoute(ROUTES.SCHEDULE_OFFER)}
          >
            Schedule
          </SecondaryButton>
          <TypeLabel classes={{ root: classes.scheduleHelperText }} align="center">
            Use this to schedule for later date or time
          </TypeLabel>
        </Box>
      </Paper>
      <Paper className={classes.campaignContainer}>
        <Tabs
          value={tableIndex}
          onChange={(a: unknown, value: number) => setTableIndex(value)}
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab label="UPCOMING CAMPAIGNS" className={classes.bold} />
          <Tab label="CAMPAIGNS HISTORY" className={classes.bold} />
        </Tabs>
        {tableIndex === 0 ? <UpcomingCampaignsTable isDebugMode={isDebugMode} /> : <CampaignHistoryTable />}
      </Paper>
    </Box>
  );
};
