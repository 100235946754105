import React, { RefObject } from 'react';
import {
  Box,
  TypeWidgetHeader,
  IconButton,
  UploadIcon,
  TypeHelper,
  CloseIcon,
  makeStyles,
  Theme,
} from '@c2fo/react-components';

interface FileInputProps {
  uploadImageRef: RefObject<HTMLInputElement>;
  fileName: string;
  setFile: (data: { fileName: string; fileData?: File }) => void;
  setSegmentId: (data: { selectedSegmentId: string; selectedSegmentName: string }) => void;
}

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  hiddenImage: {
    display: 'none',
  },
  fileContainer: {
    display: 'flex',
    border: 'dotted 1px',
    padding: spacing(1),
    margin: spacing(0, 1),
  },
  fileUpload: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}));

/* for CSV and xlsx use :
accept = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
*/

export const FileInput = ({ uploadImageRef, fileName, setFile, setSegmentId }: FileInputProps) => {
  const classes = useStyles();

  const handleFileChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = target;

    if (!files?.length) {
      return;
    }

    const { name } = files[0];

    setFile({
      fileName: name,
      fileData: files[0],
    });
    setSegmentId({ selectedSegmentId: '', selectedSegmentName: '' });
  };

  const onClear = () => {
    if (uploadImageRef?.current) {
      // eslint-disable-next-line no-param-reassign
      uploadImageRef.current.value = '';
    }

    setFile({ fileName: '' });
  };

  return (
    <Box className={classes.fileUpload}>
      <TypeWidgetHeader>Upload CSV</TypeWidgetHeader>
      <input
        data-testid="FileInput-hidden-input"
        type="file"
        ref={uploadImageRef}
        accept=".csv"
        className={classes.hiddenImage}
        onChange={handleFileChange}
      />
      <Box>
        <IconButton data-testid="FileInput-UploadIcon-button" onClick={() => uploadImageRef.current?.click()}>
          <UploadIcon />
        </IconButton>
      </Box>
      {fileName && (
        <Box className={classes.fileContainer}>
          <TypeHelper>{fileName}</TypeHelper>
          <IconButton data-testid="FileInput-CloseIcon-button" size="small" onClick={onClear}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
